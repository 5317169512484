import React from "react"
import BackgroundImage from "gatsby-background-image"
import { useStaticQuery, graphql } from "gatsby"
import { motion } from "framer-motion"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  Container,
  CTALink,
  MainHeading,
  Text,
} from "../components/styled/Styled"
import { MEDIA_QUERIES, COLORS, FONT_FAMILIES } from "../constants"

const Section = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  background-color: ${COLORS.GREY};
  padding: 4rem;

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    padding: 2rem;
    article {
      width: 100%;
    }
  }
`

const Image = styled(BackgroundImage)`
  width: 100%;
  height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: ${MEDIA_QUERIES.SMALL}) {
    height: 400px;
  }
`

const Paragraph = styled.p`
  text-transform: uppercase;
  text-align: center;
  color: ${COLORS.SECONDARY};
  font-family: ${FONT_FAMILIES.BUTTON};
  font-size: 1.6rem;
  margin-top: 3rem;
  &:first-child {
    margin-top: 0;
  }
  @media (max-width: ${MEDIA_QUERIES.SMALL}) {
    font-size: 1.2rem;
  }
`

const StyledText = styled(Text)`
  text-align: center;
  margin: auto;
  width: 65%;

  @media (max-width: ${MEDIA_QUERIES.SMALL}) {
    width: 100%;
  }
`

const SupportPage = () => {
  const { supportNoi } = useStaticQuery(graphql`
    query {
      supportNoi: file(relativePath: { eq: "DONNA-3.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Support Us" />
      <Container>
        <MainHeading style={{ textAlign: "center" }}>SUPPORT US</MainHeading>
        <Image fluid={supportNoi.childImageSharp.fluid} />

        <Section
          initial={{ opacity: 0, x: 400 }}
          animate={{
            opacity: 1,
            x: 0,
            transition: {
              delay: 0.5,
              duration: 0.75,
              ease: "easeOut",
              type: "spring",
              damping: 50,
            },
          }}
        >
          <Paragraph>Help us create new art</Paragraph>
          <StyledText>
            Would you like to invest in the future of Company NOI? Your gift
            helps to support us and enables it to create and produce new art.
          </StyledText>

          <CTALink target="_blank" href="https://gogetfunding.com/companynoi/">
            DONATE
          </CTALink>
        </Section>
      </Container>
    </Layout>
  )
}

export default SupportPage
